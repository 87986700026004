import { useUser } from "src/providers/User";
import React, { forwardRef, RefObject, useState } from "react";
import { useTenant } from "src/providers/Tenant";
import { ISO4217 } from "@samsonvt/shared-types/orderLambda";
import { LoadingSpinner } from "src/components/Loading";
import { Toast } from "src/components/Toast";
import { FormControl, RadioGroup } from "@mui/material";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPoundSign, faDollarSign, faEuroSign } from "@fortawesome/pro-thin-svg-icons";
import { Container, CurrencyButton, Icon, StyledDropdownMenu, Title } from "./styles";
import DropdownItem from "./DropdownItem";

function getFAIconFromCurrency(currency: string): IconDefinition {
  switch (currency) {
    case 'EUR':
      return faEuroSign
    case 'USD':
      return faDollarSign
    case 'GBP':
    default:
      return faPoundSign
  }
}

// 2023-10-23 - TA: Provide a forwarded ref to satisfy Mui ButtonBase when using the component prop
export const CurrencySwitcher = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const { currencies } = useTenant();
  const {
    userDetails: { currency, applyUserDetails, isApplyUserDetailsError, isApplyUserLoading },
  } = useUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClick = () => {
    setAnchorEl(null);
  };
  const handleClose = (selectedCurrency: ISO4217) => () => {
    handleMenuClick();
    applyUserDetails({ currency: selectedCurrency });
  };

  if (!currencies || currencies.length <= 1) {
    return <div ref={ref as RefObject<HTMLDivElement>}>{children}</div>;
  }

  if (isApplyUserLoading) {
    return <LoadingSpinner height="72" width="90" viewBox="25 25 50 50" />;
  }

  return (
    // div is needed to stop the toast from interfering with the tab selection.
    <Container>
      <CurrencyButton
        onClick={handleClick}
        data-testid="currency-switcher"
        sx={{ ':hover': { backgroundColor: 'unset' } }}
      >
        <Icon className='fa-xl MuiTab-iconWrapper' icon={getFAIconFromCurrency(currency)} />
        <Title variant="button">Currency</Title>
      </CurrencyButton>
      <StyledDropdownMenu
        // 2023-10-23 - TA: Use forwarded ref even though we have no use for it
        ref={ref as RefObject<HTMLDivElement>}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleMenuClick}
        color="primary"
      >
        <FormControl>
          <RadioGroup value={currency}>
            {currencies?.map((tenantDefinedCurrency) => (
              <DropdownItem
                key={tenantDefinedCurrency}
                onClick={handleClose(tenantDefinedCurrency)}
                currency={tenantDefinedCurrency}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </StyledDropdownMenu>
      <Toast
        severity="error"
        title="Error"
        message="There has been an error switching currency"
        dependency={isApplyUserDetailsError}/>
      {children}
    </Container>
  );
});
