import { alpha, AppBar, css, styled } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import DesktopHeader from './Desktop';
import MobileHeader from './Mobile';

export const PAGES = {
  my_orders: { path: '/my-orders', label: 'My Orders' },
  received_orders: { path: '/received-orders', label: 'Received Orders' },
  shopping_cart: { path: '/cart', label: (itemsInCart: number) => `Shopping cart (${itemsInCart})` },
  library: { path: '/product-library', label: 'Library' },
  my_account: { path: '/my-account', label: 'My Account' },
} as const;

interface HeaderProps {
  isWithinIframe: boolean;
  isMobile: boolean;
  setOpenMobileProductMenu: Dispatch<SetStateAction<boolean>>;
}

export function Header({ isWithinIframe, isMobile, setOpenMobileProductMenu }: HeaderProps) {
  if (isWithinIframe) {
    return null;
  }

  return (
    <StyledAppBar position="static" elevation={0} isMobile={isMobile}>
      {isMobile ? <MobileHeader setOpenMobileProductMenu={setOpenMobileProductMenu} /> : <DesktopHeader />}
    </StyledAppBar>
  );
}

const shouldForwardProp = (prop: string) => prop !== 'isMobile';

const StyledAppBar = styled(AppBar, { shouldForwardProp })<{ isMobile: boolean }>`
  ${({ theme, isMobile }) => css`
    background-color: ${theme.palette.brand.main};
    z-index: ${isMobile ? theme.zIndex.drawer - 1 : theme.zIndex.drawer + 1};
    border-bottom: 1px solid ${alpha(theme.palette.primary.main, 0.3)}};
  `}
`;
