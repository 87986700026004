import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styled } from '@mui/material';
import { onAuthUIStateChange, AuthState, Translations } from '@aws-amplify/ui-components';
import { I18n } from '@aws-amplify/core';

import { FlexContainer } from 'src/components/FlexContainer';
import { getPasswordPolicy } from 'src/services/api';
import { useEffectWithCancellation } from 'src/services/axios';
import { error as logError } from 'src/services/log';
import { spacing } from 'src/constants/spacing';
import { ProcessingOverlay } from 'src/components/Loading/ProcessingOverlay';
import { TenantContext } from 'src/providers/Tenant';

export const UnauthenticatedContainer = styled(FlexContainer)`
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: ${({ theme }) => theme.palette.brand.main};
  color: ${({ theme }) => theme.palette.grey['500']};
  padding: ${spacing.largeSpacing};
  padding-bottom: 0;
  overflow-y: auto;
`;

export const StyledLogo = styled('img')`
  margin-bottom: ${spacing.largeSpacing};
  max-height: 80px;
  width: auto;
`;

const Scroll = styled('div')`
  height: 100%;
  overflow: auto;
`;
export interface LocationType {
  redirectUrl?: string;
  emailAddress?: string;
  temporaryPassword?: string;
}

const caseMessage = (lowercase: boolean, uppercase: boolean) => {
  if (lowercase && uppercase) {
    return 'contain uppercase and lowercase letters';
  }
  if (lowercase) {
    return 'contain lowercase letters';
  }
  if (uppercase) {
    return 'contain uppercase letters';
  }
};

export function UnauthenticatedTemplate({ children }: any) {
  const history = useHistory();
  const location = useLocation<LocationType>();
  const { name: tenantName } = useContext(TenantContext);

  useEffectWithCancellation('get password policy', async (cancelToken) => {
    try {
      const passwordPolicy = await getPasswordPolicy(cancelToken);
      const rules = [];
      if (passwordPolicy?.MinimumLength) {
        rules.push(`have at least ${passwordPolicy?.MinimumLength} characters`);
      }
      const letterCase = caseMessage(passwordPolicy?.RequireLowercase, passwordPolicy?.RequireUppercase);
      if (letterCase) {
        rules.push(letterCase);
      }
      if (passwordPolicy?.RequireNumbers) {
        rules.push('contain at least one number');
      }
      if (passwordPolicy?.RequireSymbols) {
        rules.push('contain at least one symbol');
      }

      const ruleMessage = rules.join(', ');
      const passwordMessage = `New password (must ${ruleMessage})`;

      I18n.putVocabulariesForLanguage('en-US', {
        [Translations.NEW_PASSWORD_LABEL]: passwordMessage,
      });
      I18n.putVocabulariesForLanguage('en-GB', {
        [Translations.NEW_PASSWORD_LABEL]: passwordMessage,
      });
    } catch (error: any) {
      logError(error, 'Unable to call passwordPolicy endpoint');
    }
  });

  useEffect(() =>
    onAuthUIStateChange((newAuthState) => {
      if (newAuthState === AuthState.SignedIn) {
        history.push(location.state?.redirectUrl ?? '/product-library');
      }
    })
  );

  if (!tenantName) {
    return <ProcessingOverlay />;
  }

  return (
    <Scroll>
      <UnauthenticatedContainer data-testid="login-container">{children}</UnauthenticatedContainer>
    </Scroll>
  );
}
